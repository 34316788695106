/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import CheckWebpFeature from "./src/components/common/ggfx-client/module/webp-checker";
export { wrapRootElement } from "./src/apollo/wrap-root-element";

CheckWebpFeature();

// const addScript = url => {
//     const script = document.createElement("script")
//     script.src = url
//     script.async = true
//     document.body.appendChild(script)
// }

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
}) => {

    // Avoid to scroll to top when next page trigger click
    // But this feature should condiser with details page back scroll too
    if (location.pathname.match(/property\/for-sale/)) {
        return false;
    }
    if (location.pathname.match(/property\/to-rent/)) {
        return false;
    }
    if (location.pathname.match(/local-living\/events/)) {
        return false;
    }

    return true;
};