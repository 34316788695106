// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-property-for-sale-index-js": () => import("./../../../src/pages/property/for-sale/index.js" /* webpackChunkName: "component---src-pages-property-for-sale-index-js" */),
  "component---src-pages-property-to-rent-index-js": () => import("./../../../src/pages/property/to-rent/index.js" /* webpackChunkName: "component---src-pages-property-to-rent-index-js" */),
  "component---src-templates-career-template-js": () => import("./../../../src/templates/career-template.js" /* webpackChunkName: "component---src-templates-career-template-js" */),
  "component---src-templates-default-template-js": () => import("./../../../src/templates/default-template.js" /* webpackChunkName: "component---src-templates-default-template-js" */),
  "component---src-templates-event-template-js": () => import("./../../../src/templates/event-template.js" /* webpackChunkName: "component---src-templates-event-template-js" */),
  "component---src-templates-garden-square-template-js": () => import("./../../../src/templates/garden-square-template.js" /* webpackChunkName: "component---src-templates-garden-square-template-js" */),
  "component---src-templates-home-template-js": () => import("./../../../src/templates/home-template.js" /* webpackChunkName: "component---src-templates-home-template-js" */),
  "component---src-templates-news-videos-template-js": () => import("./../../../src/templates/news-videos-template.js" /* webpackChunkName: "component---src-templates-news-videos-template-js" */),
  "component---src-templates-office-template-js": () => import("./../../../src/templates/office-template.js" /* webpackChunkName: "component---src-templates-office-template-js" */),
  "component---src-templates-property-details-template-js": () => import("./../../../src/templates/property-details-template.js" /* webpackChunkName: "component---src-templates-property-details-template-js" */),
  "component---src-templates-team-template-js": () => import("./../../../src/templates/team-template.js" /* webpackChunkName: "component---src-templates-team-template-js" */),
  "component---src-templates-vip-details-template-js": () => import("./../../../src/templates/vip-details-template.js" /* webpackChunkName: "component---src-templates-vip-details-template-js" */)
}

